import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import App from "./App";
import { ConfigProvider } from "antd";
import createAppStore from "./store";
import * as serviceWorker from "./serviceWorker";
import ruRU from "antd/es/locale/ru_RU";
import moment from "moment";
import "moment/locale/ru";

moment.locale("ru");
const { persistor, store } = createAppStore();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ConfigProvider locale={ruRU}>
        <App />
      </ConfigProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
